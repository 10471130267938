

@font-face {
  font-family: 'Kanit-Bold';
  src:  url('./fonts/Kanit-Bold.ttf');
}

@font-face {
  font-family: 'Kanit-Light';
  src:  url('./fonts/Kanit-Medium.ttf');
}

textarea:focus,
textarea.form-control:focus,
input.form-control:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="email"]:focus,
input[type="number"]:focus,
[type="text"].form-control:focus,
[type="password"].form-control:focus,
[type="email"].form-control:focus,
[type="tel"].form-control:focus,
[contenteditable].form-control:focus {
  box-shadow: inset 0 -1px 0 #ddd;
}

.font-light{
  font-family: 'Kanit-Light';
}


.font-bold{
  font-family: 'Kanit-Light';
}

.App {

  text-align: center;
  background: linear-gradient(rgb(32, 39, 56) 0%, rgb(7, 8, 22) 100%) !important;
  width: 100vw;
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

h1{
  font-family: 'Kanit-Bold', sans-serif;
}

p{
  font-family: 'Kanit-Light', sans-serif !important;
}



.modal-content {
  border-radius: 20px !important;
  background-color: rgb(13, 17, 28) !important;
}

/* .btn-dropdown {
  border: 2px solid rgb(232, 129, 74) !important;
  border-radius: 5px !important;
  background-color: black !important;
} */

button.btn-header {
  color: black;
  border-width: 2px !important;
  border-style: solid !important;
  background-color: initial;
  border-image: linear-gradient(
      180deg,
      #f3a330 0%,
      #ffb64f 51.04%,
      #f9d853 100%
    )
    1 !important;
  padding: 0.3rem;
  margin-right: 1rem;
  border-radius: 2px !important;
  font-size: 1rem !important;
  box-shadow: none !important;
}


button.btn-connect {
  color: black;
  border-width: 2px !important;
  border-style: solid !important;
  background-color: rgb(13, 17, 28);
  border-image: linear-gradient(180deg,#c471f5 0%, #fa71cd 100%) 1 !important;
  padding: 0.3rem;
  padding-left: 0.7rem;
  font-family: 'Kanit-Light';
  padding-right: 0.7rem;
  border-radius: 5px !important;
  font-size: 1rem !important;
  box-shadow: none !important;
}

/* .btn-dropdown {
  border: 2px solid #01e1ff !important;
  border-radius: 5px !important;
  background-color: white !important;
} */

.dropdown-menu {
  background-color: white !important;
  color: black !important;
  font-weight: bold !important;
}

.dropdown-item {
  background-color: white !important;
  color: black !important;
}

a.dropdown-item:hover {
  background-color: white;
  color: black !important;
}
input:focus {
  outline: none !important;
  background-color: #eeeaf4 !important;

}

.input-dck {
  background-color: #eeeaf4;
  text-align: right;
  font-family: 'Kanit-Light';
  outline: none !important;
  border: none;
  font-size: 1.5rem !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  background-color: #eeeaf4;
}

.header-nav {
  position: relative;
  height: 100%;
}

.box-button {
  margin-left: 2rem;
}

.header-nav:hover .line-1 {
  display: block !important;
}

.header-nav:hover .line-2 {
  display: block !important;
}

.btn-primary.disabled,
.btn-primary:disabled {
  background-color: initial !important;
}

.line-1 {
  width: 100%;
  position: absolute;
  top: 0px;
  height: 6px;
  display: none;
}

.line-2 {
  width: 100%;
  position: absolute;
  bottom: 0px;
  height: 6px;
  display: none;
}

dropdown-item:active {
  background-color: "#000" !important;
}

button.btn-header:hover {
  background-color: initial !important;
  outline: 0 !important;
}

button.btn-header:focus {
  background-color: initial !important;
  outline: 0 !important;
}

.container-navbar {
  padding: 0px 6rem;
}

.navbar-light .navbar-toggler {
  border-color: rgba(255, 255, 255, 1) !important;
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E") !important;
}

.modal-content {
  border-radius: 20px !important;
  background-color: rgb(13, 17, 28) !important;
}


.btn-dropdown:hover {
  border: 2px solid rgb(13, 17, 28) !important;
  border-radius: 20px;
  border-image: linear-gradient(180deg,#c471f5 0%, #fa71cd 100%) 1 !important;
  background: linear-gradient(rgb(32, 39, 56) 0%, rgb(7, 8, 22) 100%) !important;
  border-radius: 5px !important;
  color: white;
}

.btn-dropdown:active {
  border: 2px solid rgb(13, 17, 28) !important;
  border-radius: 20px;
  border-image: linear-gradient(180deg,#c471f5 0%, #fa71cd 100%) 1 !important;
  background: linear-gradient(rgb(32, 39, 56) 0%, rgb(7, 8, 22) 100%) !important;
  border-radius: 5px !important;
  color: white;
}

.btn-dropdown:focus {
  border: 2px solid rgb(13, 17, 28) !important;
  border-radius: 20px;
  border-image: linear-gradient(180deg,#c471f5 0%, #fa71cd 100%) 1 !important;
  background: linear-gradient(rgb(32, 39, 56) 0%, rgb(7, 8, 22) 100%) !important;
  border-radius: 5px !important;
  color: white;
}


.btn-dropdown {
  border: 2px solid rgb(13, 17, 28) !important;
  border-image: linear-gradient(180deg,#c471f5 0%, #fa71cd 100%) 1 !important;
  background: linear-gradient(rgb(32, 39, 56) 0%, rgb(7, 8, 22) 100%) !important;
  border-radius: 5px !important;
  color: white;

}

.pointer {cursor: pointer;}

.modal-form{
        border-radius: 5px; /*1*/
        border-width: 3px;
        border: 4px solid transparent; /*2*/
        background: linear-gradient(180deg,#c471f5,#fa71cd) border-box; /*3*/
        position: 'relative';
        z-index: -1;
        -webkit-mask: /*4*/
        linear-gradient(#fff 0 0) padding-box,
        linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor; /*5'*/
        mask-composite: exclude; /*5*/

}

.dropdown-item{
  background: linear-gradient(rgb(32, 39, 56) 0%, rgb(7, 8, 22) 100%) !important;
  color: white !important;
}


.dropdown-menu {
  background: linear-gradient(rgb(32, 39, 56) 0%, rgb(7, 8, 22) 100%) !important;
  color: white !important;
  font-weight: bold !important;
  width: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 767px) {
  .img-logo {
      width: 40px !important;
  }

  .text-logo {
    font-size: 20px !important;
  }
}


.webpack-dev-server-client-overlay{
  display:  none !important;
}